import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select/dist/esm/tom-select.popular";

// Connects to data-controller="ts--select"
export default class extends Controller {
  connect() {
    new TomSelect(this.element, {
      plugins: {
        remove_button: {
          title: "Retirer ce choix",
        },
      },
      render: {
        option: function (data, escape) {
          if (data.initials && data.initials.length > 0) {
            let image;
            if (data.image && data.image.length > 0) {
              image = `<img src="${data.image}" width="32" height="32" class="rounded-circle img-fluid me-3" />`;
            } else {
              image = `<div class="profile-image me-3" style="width: 32px; height: 32px;"><p class="fw-bold mb-0">${data.initials}</p></div>`;
            }
            return (
              "<div class='d-flex align-items-center'>" +
              image +
              escape(data.text) +
              "</div>"
            );
          } else {
            return "<div>" + escape(data.text) + "</div>";
          }
        },
        no_results: function (data, escape) {
          return '<div class="no-results">Aucun résultat trouvé</div>';
        },
      },
    });
  }
}
