import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js"
import { stringify, parse } from 'query-string';


export default class extends Controller {
  static values = {
    url: String,
  }

  change(event) {
    let checkedAuthorizedUserCbs = document.querySelectorAll("fieldset.mission_authorized_users input.check_boxes:checked")
    let checkedAuthorizedUserIds = Array.from(checkedAuthorizedUserCbs).map((checkbox) => checkbox.value)
    let userIds = Array.from(event.target.selectedOptions).map((option) => option.value)
    let userIdsQueryUrl = stringify({user_ids: userIds}, { arrayFormat: 'bracket' });
    let authorizedUserIdsQueryUrl = stringify({authorized_user_ids: checkedAuthorizedUserIds}, { arrayFormat: 'bracket' });
    let queryUrl = `${this.urlValue}?${userIdsQueryUrl}&${authorizedUserIdsQueryUrl}`

    get(queryUrl, {
      responseKind: 'turbo-stream'
    })
  }
}
