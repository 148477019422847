import { Controller } from "@hotwired/stimulus";

// Connect to input with data-controller="sidebar"
export default class extends Controller {
  connect() {
    if (localStorage.getItem("sidebar")) {
      document.body.classList.add(`sidebar-${localStorage.getItem("sidebar")}`);
    } else {
      localStorage.setItem("sidebar", "open");
      document.body.classList.add("sidebar-open");
    }
    window.addEventListener("resize", this.onResize.bind(this), {
      passive: true,
    });
  }

  toggle(event) {
    const isOpen = document.body.classList.contains("sidebar-open");
    if (isOpen) {
      document.body.classList.remove("sidebar-open");
      document.body.classList.add("sidebar-closed");
    } else {
      document.body.classList.remove("sidebar-closed");
      document.body.classList.add("sidebar-open");
    }
    localStorage.setItem("sidebar", isOpen ? "closed" : "open");
  }

  onResize(event) {
    if (
      window.innerWidth < 992 &&
      document.body.classList.contains("sidebar-open")
    ) {
      this.toggle();
    }
  }
}
