import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr";

const defaultConfig = {
  dateFormat: "Y-m-d",
  altInput: true,
  altFormat: "d/m/Y",
  locale: French,
};

const taskConfig = {
  dateFormat: "Y-m-d",
  altInput: true,
  altFormat: "d/m/Y",
  minDate: "today",
  locale: French,
};

const dateTimeConfig = {
  enableTime: true,
  dateFormat: "Y-m-d H:i",
  altInput: true,
  altFormat: "d/m/Y H:i",
  locale: French,
};

// Connect to input with data-controller="datepicker"
export default class extends Controller {
  static values = {
    type: String,
  };

  connect() {
    let config;
    if (this.typeValue) {
      if (this.typeValue === "date") {
        config = defaultConfig;
      } else if (this.typeValue === "datetime") {
        config = dateTimeConfig;
      } else if (this.typeValue === "task") {
        config = taskConfig;
      } else {
        config = defaultConfig;
      }
    } else {
      config = defaultConfig;
    }

    this.instance = flatpickr(this.element, config);
  }

  disconnect() {
    this.instance.destroy();
  }
}
