import { Controller } from "@hotwired/stimulus";

// Connect to input with data-controller="collapse"
export default class extends Controller {
  static targets = ["toggle"];

  toggle(event) {
    const target = document.getElementById(event.target.dataset.target);
    new bootstrap.Collapse(target);
  }
}
