import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select/dist/esm/tom-select.popular"

// Connect to input with data-controller="tags"
export default class extends Controller {

  static values = {
    url: String
  }

  connect() {
    this.input = new TomSelect(this.element, {
      create: true,
      valueField: "name",
      labelField: "name",
      searchField: "name",
      plugins: {
        'remove_button': {
          'title': 'Retirer ce choix'
        }
      },
      render: {
        option_create: ( data, escape ) => {
          return '<div class="create">Ajouter <strong>' + escape(data.input) + '</strong>&hellip;</div>';
        },
        no_results: ( data, escape ) => {
          return '<div class="no-results">Aucun résultat trouvé</div>';
        },
      },
      onItemAdd: (value, item) => {
        this.input.setTextboxValue('')	
      },
      load: (query, callback) => {
        const csrfToken = document.querySelector("[name='csrf-token']").content
        fetch(this.urlValue, {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "X-CSRF-Token": csrfToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search: encodeURIComponent(query),
          }),
        })
				.then(response => response.json())
				.then(json => {
					callback(json.items)
				}).catch(() => {
					callback()
				})
      }
    })
  }

  disconnect() {
    this.input.destroy()
  }
}
