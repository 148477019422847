import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // filesTarget is going to contain the list of input elements we've added
  // files to - in other words, these are the input elements that we're going
  // to submit.
  static targets = ["files"];

  addFile(event) {
    // Grab some references for later
    const originalInput = event.target;
    const originalParent = originalInput.parentNode;

    // clear current selection
    this.filesTarget.replaceChildren()

    for (const file of originalInput.files) {

      // Create an element that contains our input element
      const selectedFile = document.createElement("div"); 
      selectedFile.className = "form-file-upload--display mb-2";
      originalInput.className += " d-none";
      selectedFile.append(originalInput);

      // Create label (the visible part of the new input element) with the name of
      // the selected file.
      var labelNode = document.createElement("span");
      var textElement = document.createTextNode(file.name);
      labelNode.className = "filename wrap";
      var iconNode = document.createElement("span");
      var checkNode = document.createElement("span");
      iconNode.className = "icon-file-text";
      checkNode.className = "icon-check";
      selectedFile.appendChild(iconNode);
      labelNode.appendChild(textElement);
      selectedFile.appendChild(labelNode);
      selectedFile.appendChild(checkNode);
  
      // Add the selected file to the list of selected files
      this.filesTarget.append(selectedFile);
  
      // Create a new input field to use going forward
      const newInput = originalInput.cloneNode();
  
      // Clear the filelist - some browsers maintain the filelist when cloning,
      // others don't
      newInput.value = "";
  
      // Add it to the DOM where the original input was
      originalParent.append(newInput);

    }

  }
}
