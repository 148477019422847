import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["editor"];

  connect() {
    this.initialized = false;
    addEventListener("trix-before-initialize", () => {
      this.addIndentButtonConfig();
    });
    addEventListener("trix-initialize", (event) => {
      if (!this.initialized) {
        const { toolbarElement } = event.target;
        const h1Button = toolbarElement.querySelector(
          "[data-trix-attribute=heading1]"
        );
        var h2ButtonHTML = `<button type="button" class="trix-button trix-button--icon trix-button--icon-heading-2" data-trix-attribute="heading2" title="Heading 2" tabindex="-1" data-trix-active=""><strong>H2</strong></button>`;
        var h3ButtonHTML = `<button type="button" class="trix-button trix-button--icon trix-button--icon-heading-3" data-trix-attribute="heading3" title="Heading 3" tabindex="-1" data-trix-active=""><strong>H3</strong></button>`;
        // var todoListButtonHTML = `<button type="button" class="trix-button" data-trix-attribute="todoList" title="Todo list" tabindex="-1" data-trix-active=""><strong>Todo</strong></button>`;
        // h1Button.insertAdjacentHTML("afterend", todoListButtonHTML);
        h1Button.insertAdjacentHTML("afterend", h3ButtonHTML);
        h1Button.insertAdjacentHTML("afterend", h2ButtonHTML);

        const increaseLevelButton = toolbarElement.querySelector(
          "[data-trix-action=increaseNestingLevel]"
        );
        var increaseIndentButtonHTML = `<button data-action="trix-default-toolbar#indentIncreased" type="button" class="trix-button trix-button--icon trix-button--icon-indent-increase" data-trix-action="increaseIndent" title="Increase indent" tabindex="-1" data-trix-active=""><strong>Increase indent</strong></button>`;
        var decreaseIndentButtonHTML = `<button data-action="trix-default-toolbar#indentDecreased" type="button" class="trix-button trix-button--icon trix-button--icon-indent-decrease" data-trix-action="decreaseIndent" title="Decrease indent" tabindex="-1" data-trix-active=""><strong>Decrease indent</strong></button>`;
        increaseLevelButton.insertAdjacentHTML(
          "afterend",
          decreaseIndentButtonHTML
        );
        increaseLevelButton.insertAdjacentHTML(
          "afterend",
          increaseIndentButtonHTML
        );

        this.initialized = true;
      }
    });
  }

  addIndentButtonConfig() {
    Trix.config.textAttributes.indent = {
      styleProperty: "marginLeft",
      inheritable: true,
    };
  }

  indentIncreased() {
    this.editorTarget.editor.activateAttribute("indent", "32px");
  }

  indentDecreased() {
    this.editorTarget.editor.deactivateAttribute("indent");
  }

  disconnect() {
    const toolbarElement = this.element.querySelector("trix-toolbar");
    toolbarElement.querySelector("[data-trix-attribute='heading2']").remove();
    toolbarElement.querySelector("[data-trix-attribute='heading3']").remove();
    // toolbarElement.querySelector("[data-trix-attribute='todoList']").remove();
  }
}
