// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "trix";
import "@rails/actiontext";
import "./custom/trix_customization";
import "./controllers";
import * as ActiveStorage from "@rails/activestorage";
import "./jquery";
import "./custom/libs/jquery-validation/localization/messages_fr";
import "./custom/elements/todoList";
import "./custom/elements/todoItem";
import "./custom/elements/todoInput";
import "./custom/main";
import "./custom/form";

require("@rails/ujs").start();
require("@nathanvda/cocoon");
ActiveStorage.start();

$("#note_cocoon").on("cocoon:before-remove", function (e, insertedItem) {
  fields_count = $(".nested-fields").length;
  if (fields_count <= 1) {
    e.preventDefault();
    alert("Vous devez garder au moins un bloc de contenu");
  }
});

setTimeout(function () {
  $(".alert").addClass("d-none");
}, 6000);

window.followLink = (event, url) => {
  if (
    event.target &&
    event.target.nodeName !== "A" &&
    event.target.parentElement.nodeName !== "A"
  ) {
    window.location = url;
  }
};
