import { Controller } from "@hotwired/stimulus";
// import { get } from "@rails/request.js"
import urlQueryJsonParser from "url-query-json-parser";


export default class extends Controller {
  static values = {
    url: String,
    user: String
  }

  more_fields(event) {
    
    let selectedRole = event.srcElement.labels[0].innerHTML
    let params = new URLSearchParams()
    params.append('role', selectedRole)
    let userQueryUrl = ''
    if (this.userValue !== 'null') {
      let json = {'user_attributes': JSON.parse(this.userValue)}
      userQueryUrl = urlQueryJsonParser.parseJSON(json)
    }
    let queryUrl = `${this.urlValue}?${params}&${userQueryUrl}`
    
    const frame = document.getElementById('user_role_fields');
    // Query additional form defined by role -- format HTML
    frame.src = queryUrl;
    frame.reload();
  }
}
