const tagName = (element) => element?.tagName?.toLowerCase();

Trix.config.blockAttributes.heading2 = {
  tagName: "h2",
  terminal: true,
  breakOnReturn: true,
  group: false,
};

Trix.config.blockAttributes.heading3 = {
  tagName: "h3",
  terminal: true,
  breakOnReturn: true,
  group: false,
};

// Trix.config.blockAttributes.todoList = {
//   tagName: "todo-list",
//   parse: false,
// };

// Trix.config.blockAttributes.todo = {
//   tagName: "todo-item",
//   listAttribute: "todoList",
//   group: false,
//   nestable: true,
//   test(element) {
//     return tagName(element.parentNode) === "todo-list";
//   },
// };